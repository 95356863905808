// TODO #17316: remove hardcoded womens day

import { AvatarSize, RawAvatar } from "@components/Avatar";
import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { CloudinaryImg } from "@components/CloudinaryImg";
import { Link, LinkAppearance } from "@components/Link";
import { MAX_PAGE_SECTION_WIDTH } from "@components/layout/PageSection";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import {
  GWAGD_SLUG,
  GWAGD_START_DATE,
  GWAGD_START_DATE_STG,
} from "@every.org/common/src/entity/constants";
import {
  ClientRouteName,
  URLFormat,
  getRoutePath,
} from "@every.org/common/src/helpers/clientRoutes";
import { ImageFormat } from "@every.org/common/src/helpers/cloudinary";

import { lightBgThemeNoBgCss } from "src/theme/color";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { spacing } from "src/theme/spacing";
import { TextCssVar, textSizeCss } from "src/theme/text";
import {
  RuntimeEnvironment,
  getRuntimeEnvironment,
} from "src/utility/runtimeEnvironment";

const BANNER_AVATAR_ID = "women_and_girls_day_banner_avatar_w2j8fi";
const BANNER_IMAGE_ID = "women_and_girls_day_banner_image_nrwug4";
export function useShowWomenAndGirlsDayBanner() {
  const env = getRuntimeEnvironment();
  switch (env) {
    case RuntimeEnvironment.PROD:
      return new Date() > new Date(GWAGD_START_DATE);
    case RuntimeEnvironment.STAGING:
      return new Date() > new Date(GWAGD_START_DATE_STG);
    case RuntimeEnvironment.DEV:
      return true;
    default:
      return false;
  }
}

const URL = getRoutePath({
  name: ClientRouteName.NONPROFIT_OR_CAUSE,
  tokens: {
    nonprofitSlug: GWAGD_SLUG,
  },
  format: URLFormat.RELATIVE,
});

const onlyOnSmallScreen = css`
  display: block;
  ${cssForMediaSize({
    min: MediaSize.LARGE,
    css: css`
      display: none;
    `,
  })}
`;

const StyledButton = styled(Button)`
  background-color: #fff;
  --btnColor: #836021;
  z-index: 5;
`;

const landingBannerCss = css`
  width: 100%;

  display: none;
  ${cssForMediaSize({
    min: MediaSize.LARGE,
    css: css`
      display: flex;
    `,
  })}

  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  z-index: 1;

  background: rgba(247, 229, 197, 1);
  ${lightBgThemeNoBgCss};

  .wgd-container {
    z-index: 2;
    display: flex;
    gap: ${spacing.l};
    align-items: center;
    padding: ${spacing.l};
    width: 100%;
    max-width: ${MAX_PAGE_SECTION_WIDTH};
  }

  .wgd-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
  }

  .wgd-button {
    margin-left: auto;
    flex-shrink: 0;
  }

  .wgd-title {
    ${textSizeCss.xl};
    margin-right: ${spacing.xs};
  }

  .wgd-subtitle {
    color: #836021;
    font-weight: 500;
  }

  .wgd-text {
    display: flex;
    gap: ${spacing.xs};
    flex-grow: 1;
    align-items: baseline;
    flex-wrap: wrap;
  }
`;

const mainFeedBannerCss = css`
  width: 100%;
  max-width: ${MAX_PAGE_SECTION_WIDTH};
  display: none;

  padding: 0 ${spacing.xl};
  margin: ${spacing.l} auto;

  ${cssForMediaSize({
    min: MediaSize.LARGE,
    css: css`
      display: flex;
    `,
  })}

  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  z-index: 1;

  ${lightBgThemeNoBgCss};

  .wgd-container {
    z-index: 2;
    display: flex;
    gap: ${spacing.l};
    align-items: center;
    width: 100%;
    padding-right: ${spacing.l};
    background: rgba(247, 229, 197, 1);
    border-radius: 16px;
    overflow: hidden;
    position: relative;
  }

  .wgd-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
  }

  .wgd-button {
    margin-left: auto;
    flex-shrink: 0;
  }

  .wgd-title {
    ${textSizeCss.xl};
    margin-right: ${spacing.xs};
  }

  .wgd-subtitle {
    color: #836021;
    font-weight: 500;
  }

  .wgd-text {
    padding-left: ${spacing.l};
    text-align: center;
    flex-grow: 1;
    align-items: baseline;
    flex-wrap: wrap;
  }
`;

const smallBannerCss = css`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: ${spacing.xs};
  background: linear-gradient(
      270deg,
      rgba(255, 70, 113, 0.1) 0%,
      rgba(255, 70, 113, 0) 100%
    ),
    linear-gradient(0deg, #fbf2e2, #fbf2e2);
  ${lightBgThemeNoBgCss};
  overflow: hidden;
  position: relative;
  z-index: 1;

  .wgd-container {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${spacing.l};
    width: 100%;
    max-width: ${MAX_PAGE_SECTION_WIDTH};
  }

  .wgd-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
  }

  .wgd-text {
    z-index: 2;
  }

  .wgd-title {
    ${textSizeCss.l};
    ${TextCssVar.LINE_HEIGHT}: ${28 / 16}rem;
    margin-bottom: ${spacing.xs};
  }

  .wgd-subtitle {
    color: #836021;
    font-weight: 500;
  }

  .wgd-circle {
    position: absolute;
    background-color: rgb(251, 242, 226);
    border-radius: 100%;
    z-index: 1;
  }

  .wgd-circle-1 {
    width: 131px;
    height: 131px;
    top: -81px;
    right: -4px;
  }

  .wgd-circle-2 {
    width: 61px;
    height: 61px;
    top: 57px;
    right: 63px;
  }

  .wgd-circle-3 {
    width: 220px;
    height: 220px;
    top: 17px;
    right: -167px;
  }

  .wgd-circle-4 {
    width: 23px;
    height: 23px;
    top: 32px;
    right: 120px;
  }
`;

const LearnMoreButton = ({ className }: { className?: string }) => (
  <StyledButton
    data-tname="learnMoreButton"
    role={ButtonRole.SECONDARY}
    className={className}
    onClick={{
      kind: ButtonTargetKind.LINK,
      to: URL,
    }}
  >
    Learn more
  </StyledButton>
);

export const WAGLandingPageBanner = ({ className }: { className?: string }) => {
  return (
    <React.Fragment>
      <div className={className} css={landingBannerCss}>
        <div className="wgd-container">
          <RawAvatar
            customCSS={{ width: "72px", height: "72px", zIndex: 2 }}
            size={AvatarSize.MEDIUM}
            cloudinaryId={BANNER_AVATAR_ID}
          />
          <div className="wgd-text">
            <h3 className="wgd-title">Give to Women and Girls Day</h3>
            <p className="wgd-subtitle">
              Explore over 50,000 nonprofits serving women and girls
            </p>
          </div>
          <LearnMoreButton className="wgd-button" />
        </div>
        <Link
          data-tname="wgd-link"
          className="wgd-link"
          to={URL}
          appearance={LinkAppearance.UNSTYLED}
          title="Learn more"
        />
      </div>
      <SmallBanner className={className} css={onlyOnSmallScreen} />
    </React.Fragment>
  );
};

export const WAGMainFeedPageBanner = ({
  className,
}: {
  className?: string;
}) => {
  return (
    <React.Fragment>
      <div className={className} css={mainFeedBannerCss}>
        <div className="wgd-container">
          <CloudinaryImg
            cloudinaryId={BANNER_IMAGE_ID}
            imageFormat={ImageFormat.svg}
            height={104}
            width={153}
            layout="fixed"
          />
          <div className="wgd-text">
            <h3 className="wgd-title">Give to Women and Girls Day</h3>
            <p className="wgd-subtitle">
              Explore over 50,000 nonprofits serving women and girls
            </p>
          </div>
          <LearnMoreButton className="wgd-button" />
        </div>
        <Link
          data-tname="wgd-link"
          className="wgd-link"
          to={URL}
          appearance={LinkAppearance.UNSTYLED}
          title="Learn more"
        />
      </div>
      <SmallBanner className={className} css={onlyOnSmallScreen} />
    </React.Fragment>
  );
};

export const SmallBanner = ({ className }: { className?: string }) => {
  return (
    <div className={className} css={smallBannerCss}>
      <div className="wgd-container">
        <div className="wgd-text">
          <h3 className="wgd-title">
            Give to
            <br /> Women and Girls Day
          </h3>
          <p className="wgd-subtitle">
            Explore over 50,000 nonprofits serving women and girls
          </p>
        </div>
        <RawAvatar
          customCSS={{ width: "72px", height: "72px", zIndex: 2 }}
          size={AvatarSize.MEDIUM}
          cloudinaryId={BANNER_AVATAR_ID}
        />
      </div>
      <div className="wgd-circle wgd-circle-1" />
      <div className="wgd-circle wgd-circle-2" />
      <div className="wgd-circle wgd-circle-3" />
      <div className="wgd-circle wgd-circle-4" />
      <Link
        data-tname="wgd-link"
        className="wgd-link"
        to={URL}
        appearance={LinkAppearance.UNSTYLED}
        title="Learn more"
      />
    </div>
  );
};
