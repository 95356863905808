import { ButtonRole, ButtonTargetKind } from "@components/Button";
import { BackgroundProvider } from "@components/Home/LandingPage/BackgroundProvider";
import { LandingContent } from "@components/Home/LandingPage/LandingContent";
import { LandingFeed } from "@components/Home/LandingPage/LandingFeed";
import { LandingTab } from "@components/Home/LandingPage/LandingTab";
import {
  defaultPageLayoutCss,
  defaultPageLayoutFooterCss,
  LandingContentWrapper,
  LandingContentTabsContainer,
  StyledCard,
  pageSectionCss,
  LandingSeeRecentDonationsButtonsSection,
  LandingSeeRecentDonationsButton,
  landingFeedPageSectionCss,
} from "@components/Home/LandingPage/styles";
import { LandingUserTypeTab } from "@components/Home/LandingPage/types";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import {
  WAGLandingPageBanner,
  useShowWomenAndGirlsDayBanner,
} from "@components/WomenAndGirlsBanner";
import { DefaultPageLayout } from "@components/layout/DefaultPageLayout";
import { PageSection } from "@components/layout/PageSection";
import { css } from "@emotion/react";
import { NextPage } from "next";
import { useMemo, useState } from "react";

import { FeedItemResponse } from "@every.org/common/src/codecs/entities";
import { FeedItemType } from "@every.org/common/src/entity/types";
import { LoggedOutAATest } from "@every.org/common/src/helpers/abtesting/LoggedOutAATest";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";

import { LoadImageWithPriorityProvider } from "src/context/LoadImageWithPriorityContext/LoadImageWithPriorityContext";
import { EdoTheme } from "src/context/ThemeContext";
import {
  minWidthForMediaSize,
  MediaSize,
  cssForMediaSize,
} from "src/theme/mediaQueries";
import { horizontalStackCss } from "src/theme/spacing";
import { useABTestTrack, useStatSigLayer } from "src/utility/abtesting";
import { getWindow } from "src/utility/window";

type LandingPageProps = {
  feedItems?: FeedItemResponse[];
};

export const LandingPage: NextPage<LandingPageProps> = ({ feedItems }) => {
  // A/A test, don't actually do anything but split users into different variants
  // Metrics should not differ between the two
  useABTestTrack(LoggedOutAATest);

  const defaultTab = useStatSigLayer("landing_page")?.get<boolean>(
    "defaultNonprofitsTab",
    false
  )
    ? LandingUserTypeTab.NONPROFITS
    : LandingUserTypeTab.DONORS;

  const tabsInOrder = useMemo(
    () =>
      defaultTab === LandingUserTypeTab.NONPROFITS
        ? [LandingUserTypeTab.NONPROFITS, LandingUserTypeTab.DONORS]
        : [LandingUserTypeTab.DONORS, LandingUserTypeTab.NONPROFITS],
    [defaultTab]
  );

  const [selectedTab, setSelectedTab] =
    useState<LandingUserTypeTab>(defaultTab);

  const showGetStarted =
    defaultTab !== LandingUserTypeTab.NONPROFITS &&
    selectedTab === LandingUserTypeTab.NONPROFITS;

  const priorityItems =
    feedItems
      ?.slice(0, 4)
      .map((item) =>
        item.type === FeedItemType.USER_DONATION
          ? (item.donationCharge.toNonprofitId as string)
          : ""
      ) || [];

  const scrollToRecentDonations = () => {
    const window = getWindow();
    if (window) {
      window.scrollTo({
        top:
          window.innerHeight -
          (window.innerWidth < minWidthForMediaSize[MediaSize.MEDIUM] ? 0 : 75),
        behavior: "smooth",
      });
    }
  };

  const showWAGBanner = useShowWomenAndGirlsDayBanner();

  return (
    <LoadImageWithPriorityProvider initialValue={{ ids: priorityItems }}>
      <DefaultPageLayout
        showGetStarted={showGetStarted}
        hideSignup={showGetStarted}
        themeOverride={EdoTheme.DARK}
        css={defaultPageLayoutCss}
        footerCss={defaultPageLayoutFooterCss}
        pageTitle={
          "Every.org: Online Fundraising Platform Built for Nonprofits and Donors"
        }
        metas={{
          "og:description":
            "Every.org is a free online fundraising platform that makes it easier for charities to raise money, while creating a joyful and meaningful experience for donors.",
        }}
        // This page (/index-public) is served at / via rewrite
        canonical={
          assertEnvPresent(
            process.env.NEXT_PUBLIC_WEBSITE_ORIGIN,
            "WEBSITE_ORIGIN"
          ) + "/"
        }
      >
        {showWAGBanner && <WAGLandingPageBanner />}
        <BackgroundProvider
          css={
            showWAGBanner && [
              css`
                height: calc(100vh - 150px - 160px);
                height: calc(100svh - 150px - 160px);
              `,

              cssForMediaSize({
                min: MediaSize.MEDIUM,
                css: css`
                  height: calc(100vh - 106px - 120px);
                `,
              }),
            ]
          }
        >
          <PageSection css={{ height: "100%" }} contentCss={pageSectionCss}>
            <LandingContentWrapper>
              <LandingContentTabsContainer>
                {tabsInOrder.map((currentTab) => (
                  <LandingTab
                    key={currentTab}
                    currentTab={currentTab}
                    selectedTab={selectedTab}
                    onChange={setSelectedTab}
                  />
                ))}
              </LandingContentTabsContainer>
              <StyledCard>
                <LandingContent selectedTab={selectedTab} />
              </StyledCard>
            </LandingContentWrapper>
            <LandingSeeRecentDonationsButtonsSection>
              <LandingSeeRecentDonationsButton
                data-tname="LandingPage-ShowRecentDonations-Button"
                role={ButtonRole.PRIMARY}
                contentCss={[
                  horizontalStackCss.xs,
                  { alignItems: "center", justifyContent: "center" },
                ]}
                onClick={{
                  kind: ButtonTargetKind.FUNCTION,
                  action: scrollToRecentDonations,
                }}
              >
                <span>See recent donations</span>
                <Icon
                  iconImport={() =>
                    import("@components/Icon/icons/SelectArrowIcon")
                  }
                  size={IconSize.SMALL}
                  display={IconDisplay.CURRENT_COLOR}
                />
              </LandingSeeRecentDonationsButton>
            </LandingSeeRecentDonationsButtonsSection>
          </PageSection>
        </BackgroundProvider>
        <PageSection contentCss={landingFeedPageSectionCss}>
          <LandingFeed initialItems={feedItems} />
        </PageSection>
      </DefaultPageLayout>
    </LoadImageWithPriorityProvider>
  );
};
